import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ref, get } from 'firebase/database'; // Firebase Realtime DB functions
import { db } from '../firebase'; // Import your Firebase config
import { IoIosArrowRoundBack } from 'react-icons/io';
import { Helmet } from 'react-helmet';

function ClientLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true); // Loading state
  const navigate = useNavigate();

  // Check if user is already logged in (from localStorage)
  useEffect(() => {
    const storedEmail = localStorage.getItem('email');
    const storedPassword = localStorage.getItem('password');

    if (storedEmail && storedPassword) {
      validateUser(storedEmail, storedPassword, true); // Validate and redirect if valid
    } else {
      setLoading(false); // Stop loading if no credentials are stored
    }
  }, []);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email && password) {
      setLoading(true); // Start loading when submitting
      validateUser(email, password);
    } else {
      setError('Please fill out both fields.');
    }
  };

  // Validate user against Firebase Realtime Database
  const validateUser = async (email, password, redirectDirectly = false) => {
    try {
      const sanitizedEmail = email.split('@')[0]; // Get the part before '@' from email
      const userRef = ref(db, `users/${sanitizedEmail}`);
      const snapshot = await get(userRef);

      if (snapshot.exists()) {
        const userData = snapshot.val();

        if (userData.password === password) {
          // Save email and password to localStorage
          localStorage.setItem('email', email);
          localStorage.setItem('password', password);

          // Redirect to /client/:emailUsername
          navigate(`/client/${sanitizedEmail}`);
        } else {
          setError('Incorrect password.');
          setLoading(false); // Stop loading if there's an error
        }
      } else {
        setError('User does not exist.');
        setLoading(false); // Stop loading if there's an error
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setError('An error occurred. Please try again.');
      setLoading(false); // Stop loading if there's an error
    }
  };

  return (
    <div className="ClientLogin">
      {loading ? (
        <p>Loading...</p> // Show loading message while loading
      ) : (
        <>
            <section className='leftClientLogin'>
              <Helmet>
                  <title>سجل دخولك لتعديل الساعة</title>
              </Helmet>
              <Link to="/" className='backBtn'>الرجوع إلى الصفحة الرئيسية <IoIosArrowRoundBack /></Link>
                <img src={require('../assets/logo2.png')}></img>
                <h2>سجل دخولك لتعديل الساعة</h2>
                {error && <p className="error">{error}</p>}
                <form onSubmit={handleSubmit}>
                    <div>
                      <label>البريد الإلكتروني</label>
                      <input
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="اكتب البريد الإلكتروني..."
                          required
                      />
                    </div>
                    <div>
                      <label>كلمة المرور</label>
                      <input
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="اكتب كلمة المرور..."
                          required
                      />
                    </div>
                    <button type="submit">الدخول</button>
                </form>
            </section>
            {/* <div className='rightClientLogin'>
              <Link to="/">الرجوع إلى الصفحة الرئيسية <IoIosArrowRoundBack /></Link>
            </div> */}
        </>
      )}
    </div>
  );
}

export default ClientLogin;
