import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ref, onValue, set, remove } from "firebase/database"; // Firebase Realtime Database functions
import { db } from '../firebase'; // Your Firebase Realtime Database instance
import { toast, ToastContainer } from 'react-toastify'; // To show notifications
import 'react-toastify/dist/ReactToastify.css';
import { FaTrash, FaSearch } from 'react-icons/fa'; // Import trash and search icon for deleting
import { TiUserAddOutline } from 'react-icons/ti';
import { MdOutlineMosque } from 'react-icons/md';
import { Helmet } from 'react-helmet';

function AdminDash() {
    const [masajed, setMasajed] = useState([]);
    const [newUserEmail, setNewUserEmail] = useState('');
    const [newMsqName, setNewMsqName] = useState('');
    const [newUserPassword, setNewUserPassword] = useState(''); // New state for password
    const [newUserMobile, setNewUserMobile] = useState(''); // New state for mobile
    const [searchQuery, setSearchQuery] = useState(''); // State for search query
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false); // For showing delete confirmation popup
    const [selectedUserId, setSelectedUserId] = useState(null); // For tracking the selected user to delete
    const navigate = useNavigate();

    // Sign-out function
    const handleSignOut = () => {
        localStorage.removeItem('adminKey');
        navigate('/admin/login');
    };

    // Fetch all users (masajed) from Firebase Realtime Database
    useEffect(() => {
        const masajedRef = ref(db, 'users');
        const unsubscribe = onValue(masajedRef, (snapshot) => {
            if (snapshot.exists()) {
                const masajedData = snapshot.val();
                const masajedArray = Object.keys(masajedData).map((key) => ({
                    ...masajedData[key],
                    id: key, // Store the unique user ID
                }));
                setMasajed(masajedArray);
            }
        });

        return () => unsubscribe();
    }, []);

    // Handle user creation directly in Firebase Realtime Database
    const handleCreateUser = async (e) => {
        e.preventDefault();

        // Extract the username part of the email (before the '@')
        const usernameFromEmail = newUserEmail.split('@')[0];

        try {
            // Create a new entry in the Realtime Database under 'users/usernameFromEmail'
            const newUserRef = ref(db, `users/${usernameFromEmail}`);

            await set(newUserRef, {
                email: newUserEmail,
                msqname: newMsqName,
                password: newUserPassword, // Save password
                mobile: newUserMobile // Save mobile
            });

            toast.success('User created successfully'); // Toast notification on success

            // Clear input fields
            setNewUserEmail('');
            setNewMsqName('');
            setNewUserPassword('');
            setNewUserMobile(''); // Clear mobile input
        } catch (error) {
            toast.error(`Failed to create user: ${error.message}`);
        }
    };

    // Show confirmation popup before deleting
    const handleDeleteUser = (userId) => {
        setSelectedUserId(userId);
        setShowDeleteConfirm(true); // Show confirmation popup
    };

    // Confirm delete action
    const confirmDeleteUser = async () => {
        if (selectedUserId) {
            try {
                const userRef = ref(db, `users/${selectedUserId}`);
                await remove(userRef);
                toast.success('User deleted successfully'); // Toast notification on success
            } catch (error) {
                toast.error(`Failed to delete user: ${error.message}`);
            }
        }
        // Close the popup after deletion
        setShowDeleteConfirm(false);
        setSelectedUserId(null);
    };

    // Cancel delete action
    const cancelDeleteUser = () => {
        setShowDeleteConfirm(false);
        setSelectedUserId(null);
    };

    // Filter users based on search query
    const filteredMasajed = masajed.filter(masjed => 
        masjed.msqname?.toLowerCase().includes(searchQuery?.toLowerCase())
    );

    // Redirect to a user-specific page when clicked
    const handleUserClick = (email) => {
        const emailUsername = email.split('@')[0];
        navigate(`/masjid/${emailUsername}`);
    };

    return (
        <div className='AdminDash ClientDash'>
            <Helmet>
                <title>لوحة تحكم المشرف</title>
            </Helmet>
            <ToastContainer />
            <header>
                <label>لوحة تحكم المشرف</label>
                <button onClick={handleSignOut}>تسجيل الخروج</button>
            </header>

            <div className='g2'>
                {/* How Many Masjid we have */}
                <section className='MasajedCount'>
                    <MdOutlineMosque /> {masajed.length}
                </section>
                {/* Section to create a new user */}
                <section className='addUser'>
                    <h2><TiUserAddOutline />إضافة مستخدم جديد</h2>
                    <form onSubmit={handleCreateUser}>
                        <input
                            type="email"
                            placeholder="البريد الإلكتروني"
                            value={newUserEmail}
                            onChange={(e) => setNewUserEmail(e.target.value)}
                            required
                        />
                        <input
                            type="text"
                            placeholder="اسم المسجد"
                            value={newMsqName}
                            onChange={(e) => setNewMsqName(e.target.value)}
                            required
                        />
                        <input
                            type="password"
                            placeholder="كلمة المرور"
                            value={newUserPassword}
                            onChange={(e) => setNewUserPassword(e.target.value)}
                            required
                        />
                        <input
                            type="text"
                            placeholder="رقم الجوال"
                            value={newUserMobile}
                            onChange={(e) => setNewUserMobile(e.target.value)}
                            required
                        />
                        <button type="submit">إضافة مستخدم</button>
                    </form>
                </section> 
            </div>

            <div className='masajedHolder'>
                <h2>المساجد</h2>
                {/* Search bar with search icon */}
                <div className="search-container">
                    <input 
                        type="text" 
                        placeholder="ابحث عن المسجد"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <FaSearch className="search-icon" />
                </div>
                
            
                {/* Section to list all users */}
                <section className='innerMasajedHolder'>
                    
                    <ul>
                        {filteredMasajed.map((masjed) => (
                            <li key={masjed.id}>
                                <span onClick={() => handleUserClick(masjed.email)}>
                                    {/* - {masjed.email} */}
                                    <MdOutlineMosque />{masjed.msqname}
                                </span>
                                <FaTrash 
                                    onClick={() => handleDeleteUser(masjed.id)} 
                                    title="Delete User"
                                />
                            </li>
                        ))}
                    </ul>
                </section>
            </div>

            {/* Delete confirmation popup */}
            {showDeleteConfirm && (
                <div className="delete-confirm-popup">
                    <p>هل أنت متأكد من حذف هذا العميل؟</p>
                    <div>
                        <button onClick={confirmDeleteUser}>نعم</button>
                        <button onClick={cancelDeleteUser}>لا</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AdminDash;
