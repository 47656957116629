import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaUserShield } from 'react-icons/fa'; // Admin icon from react-icons
import { Helmet } from 'react-helmet';

function AdminLogin() {
  const [key, setKey] = useState('');
  const navigate = useNavigate();

  // Function to check if the key in local storage is valid
  useEffect(() => {
    const savedKey = localStorage.getItem('adminKey');
    if (savedKey && savedKey === process.env.REACT_APP_ADMIN_KEY) {
      navigate('/admin'); // If valid key, redirect to /admin
    }
  }, [navigate]);

  // Handle the form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (key === process.env.REACT_APP_ADMIN_KEY) {
      localStorage.setItem('adminKey', key); // Store key in local storage
      navigate('/admin'); // Redirect to /admin after successful login
    } else {
      alert('Incorrect key, please try again.');
    }
  };

  return (
    <div className='AdminLogin'>
      <Helmet>
        <title>تسجيل الدخول</title>
      </Helmet>
      <h1><FaUserShield/>تسجيل الدخول إلى لوحة تحكم المشرف</h1>
      <form onSubmit={handleSubmit} >
        <input 
          type="password" 
          placeholder="أدخل المفتاح للدخول" 
          value={key} 
          onChange={(e) => setKey(e.target.value)} 
          
        />
        <button type="submit" >تسجيل الدخول</button>
      </form>
    </div>
  );
}


export default AdminLogin;
