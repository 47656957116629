import React, { useState, useEffect, useRef } from 'react';
import { ref, set, get } from 'firebase/database';
import { ref as storageRef, uploadBytes, getDownloadURL , deleteObject  } from 'firebase/storage';
import { useNavigate, useParams } from 'react-router-dom';
import { db, storage } from '../firebase'; // Import your Firebase config
import { MdAlternateEmail, MdOutlineBookmarks, MdOutlineFileDownload, MdOutlineFullscreen, MdOutlineImage, MdOutlinePassword, MdOutlinePhoneEnabled, MdOutlineVolumeUp } from 'react-icons/md';
import { PiMosque } from 'react-icons/pi';
import { FaRegEye } from 'react-icons/fa';
import { TbBooks, TbWorldLatitude, TbWorldLongitude } from 'react-icons/tb';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Helmet } from 'react-helmet';

const MosqueSettings = () => {
  const { emailUserName } = useParams(); // Retrieve emailUserName from URL
  const navigate = useNavigate(); // To redirect if needed

  // Refs for file inputs
  const imageInputRef = useRef(null);
  const adhanInputRef = useRef(null);

  // State variables for form fields
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [mobile, setMobile] = useState('');
  const [msqName, setMsqName] = useState('');
  const [imagePreview, setImagePreview] = useState('');
  const [adhanPreview, setAdhanPreview] = useState('');
  const [loading, setLoading] = useState(false);
  const [hijriDate, setHijriDate] = useState("")
  const [advertisement, setadvertisement] = useState('')
  const [screen, setscreen] = useState('')
  const [hadeeth, sethadeeth] = useState('')
  const [lat, setLat] = useState('');
  const [long, setLong] = useState('');
  const [fajr, setFajr] = useState('');
  const [sunrise, setSunrise] = useState('');
  const [dhuhr, setDhuhr] = useState('');
  const [asr, setAsr] = useState('');
  const [maghrib, setMaghrib] = useState('');
  const [isha, setIsha] = useState('');

  // Sign-out function
  const handleSignOut = () => {
    localStorage.removeItem('email');
    localStorage.removeItem('password');
    navigate('/client/login');
  };

  // Check localStorage for email and password
  useEffect(() => {
    const storedEmail = localStorage.getItem('email');
    const storedPassword = localStorage.getItem('password');
    
    if (!storedEmail || !storedPassword) {
      // Redirect to login if no email or password in localStorage
      navigate('/client/login');
      return;
    }

    const storedUserName = storedEmail.split('@')[0];

    if (storedUserName !== emailUserName) {
      // Redirect if the username does not match
      navigate('/client/login');
      return;
    }

    // Fetch user data from Firebase and validate password
    const refRec = ref(db, `users/${emailUserName}`);
    get(refRec)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          if (data.password === storedPassword) {
            fillFormFields(data);
            fetchHijriDate(data.lat, data.long); // Fetch Hijri date using lat and long
          } else {
            // Redirect to login if password does not match
            navigate('/client/login');
          }
        } else {
          console.error('User not found in Firebase');
          navigate('/client/login');
        }
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
        navigate('/client/login');
      });
  }, [emailUserName, navigate]);

  // Fetch 3 Books To Show Them To Immem
  const [books, setBooks] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://api3.islamhouse.com/v3/paV29H2gm56kvLPy/main/books/ar/ar/${Math. floor(Math. random()*10) + 1 }/6/json`);
        const result = await response.json();
        setBooks(result?.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // Fill form fields with fetched data
  const fillFormFields = (data) => {

    setEmail(data.email || '');
    setPassword(data.password || '');
    setMobile(data.mobile || '');
    setMsqName(data.msqname || '');
    setLat(data.lat || '');
    setLong(data.long || '');
    setFajr(data.fajr || '');
    setSunrise(data.sunrise || '');
    setDhuhr(data.dhuhr || '');
    setAsr(data.asr || '');
    setMaghrib(data.maghrib || '');
    setIsha(data.isha || '');
    setadvertisement(data.advertisement || '')
    setscreen(data.screen || '')
    sethadeeth(data.hadeeth || '')

    // Set image and adhan previews if they exist
    if (data.backgroundImage && data.backgroundImage !== 'noBg') {
      setImagePreview(data.backgroundImage);
    }

    if (data.adhan && data.adhan !== 'noAdhan') {
      setAdhanPreview(data.adhan);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const newData = {
      email,
      password,
      mobile,
      advertisement,
      msqname: msqName,
      lat,
      long,
      fajr : fajr || "10",
      sunrise : sunrise || "10",
      dhuhr : dhuhr || "15",
      asr : asr || "10",
      maghrib : maghrib || "5",
      isha : isha || "15",
      screen : screen || "H",
      hadeeth,
      backgroundImage: imagePreview || 'noBg',
      adhan: adhanPreview || 'noAdhan',
    };

    try {
      setLoading(true);
      await set(ref(db, `users/${email.split('@')[0].replace(/[.#$\[\]]/g, '')}`), newData);
      toast.success('تم تحديث البيانات بنجاح', {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
      window.open(`${window.location.origin}/masjid/${emailUserName}`)
    } catch (error) {
      console.error('Error updating data:', error);
    } finally {
      setLoading(false);
    }
  };

  // Handle background image upload
  const handleImageUpload = async (file) => {
    setLoading(true); // Start loading

    try {
      // Delete the previous image if it exists
      if (imagePreview) { 
        try {
          const oldImagePath = decodeURIComponent(new URL(imagePreview).pathname.split("/o/")[1]);
          const oldImageRef = storageRef(storage, oldImagePath);
          await deleteObject(oldImageRef); // Delete the old image
          console.log("Previous image deleted successfully.");
        } catch (error) {
          console.error("Error deleting previous image:", error);
        }
      }

      // Upload the new image
      const newImagePath = `backgroundImages/${email.split('@')[0]}/${file.name}`;
      const newImageRef = storageRef(storage, newImagePath);
      const uploadTask = await uploadBytes(newImageRef, file);
      const url = await getDownloadURL(uploadTask.ref);

      // Update the Firebase database with the new image URL
      await set(ref(db, `users/${email.split('@')[0].replace(/[.#$\[\]]/g, '')}/backgroundImage`), url);

      // Set the new image as the preview
      setImagePreview(url);
      console.log("New image uploaded successfully.");
    } catch (error) {
      console.error('Error uploading image:', error);
    } finally {
      setLoading(false); // End loading
    }
  };

  // Handle Adhan file upload
  const handleAdhanUpload = async (file) => {
    setLoading(true); // Start loading
    try {
      // Delete the previous Adhan file if it exists
      if (adhanPreview) { 
        try {
          const oldAdhanPath = decodeURIComponent(new URL(adhanPreview).pathname.split("/o/")[1]);
          const oldAdhanRef = storageRef(storage, oldAdhanPath);
          await deleteObject(oldAdhanRef); // Delete the old Adhan file
          console.log("Previous Adhan deleted successfully.");
        } catch (error) {
          console.error("Error deleting previous Adhan:", error);
        }
      }

      // Define the new path for the Adhan file inside the user's folder
      const userFolder = email.split('@')[0]; // Using the first part of the email as the folder name
      const adhanPath = `adhans/${userFolder}/${file.name}`; // Storing the Adhan in a user-specific folder
      const adhanRef = storageRef(storage, adhanPath);
      
      // Upload the new Adhan file
      const uploadTask = await uploadBytes(adhanRef, file);
      const url = await getDownloadURL(uploadTask.ref);

      // Update the Firebase database with the new Adhan file URL
      await set(ref(db, `users/${userFolder}/adhan`), url);

      // Set the new Adhan file as the preview
      setAdhanPreview(url);
      console.log("New Adhan uploaded successfully.");
    } catch (error) {
      console.error('Error uploading Adhan:', error);
    } finally {
      setLoading(false); // End loading
    }
  };

  // Trigger input click via button
  const handleImageChange = () => {
    if (imageInputRef.current) {
      imageInputRef.current.click();
    }
  };

  const handleAdhanChange = () => {
    if (adhanInputRef.current) {
      adhanInputRef.current.click();
    }
  };

  // Fetch Hijri date using the Aladhan API
  const fetchHijriDate = async (latitude, longitude) => {
    const today = new Date();
    const dateString = `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`; // Format the date as DD-MM-YYYY
    const apiUrl = `https://api.aladhan.com/v1/timings/${dateString}?latitude=${latitude}&longitude=${longitude}`;

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();
      if (data && data.code === 200) {
        const hijri = data.data.date.hijri;
        const formattedHijriDate = `${hijri.weekday.ar} , ${Number(hijri.day)+1} ${hijri.month.ar} , ${hijri.year} هـ`;
        setHijriDate(formattedHijriDate);
      }
    } catch (error) {
      console.error('Error fetching Hijri date:', error);
    }
  };

  return (
    <div className="ClientDash">
      {loading && <div id="loadingScreen">تحميل...</div>}
      <ToastContainer />
      <Helmet>
        <title>إعدادات الساعة</title>
      </Helmet>
      <header>
        <label>إعدادات الساعة</label>
        <button onClick={handleSignOut}>تسجيل الخروج</button>
      </header>

      <main>
        <div className="form-container">
          <form onSubmit={handleSubmit}>
            <div className="innerFormHolder">
              <div className="rightSideForm">
              <div className="field">
                  <label><MdAlternateEmail />البريد الإلكتروني</label>
                  <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className="field">
                  <label><MdOutlinePassword />كلمة السر</label>
                  <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
                <div className="field">
                  <label><MdOutlinePhoneEnabled />رقم الجوال</label>
                  <input type="text" value={mobile} onChange={(e) => setMobile(e.target.value)} />
                </div>
                <div className="field">
                  <label><PiMosque />إسم المسجد</label>
                  <input type="text" value={msqName} onChange={(e) => setMsqName(e.target.value)} />
                </div>
                <div className="field">
                  <label><FaRegEye  />إعلان</label>
                  <input type="text" value={advertisement} onChange={(e) => setadvertisement(e.target.value)} />
                </div>
                <div className="field">
                  <label><MdOutlineBookmarks   />حديث</label>
                  <input type="text" value={hadeeth} onChange={(e) => sethadeeth(e.target.value)} />
                </div>
                <div className="field">
                  <label><MdOutlineFullscreen />شكل الشاشة</label>
                  <div>
                    <label>
                      <input
                        type="radio"
                        name="screen"
                        value="V"
                        checked={screen === "V"}
                        onChange={(e) => setscreen(e.target.value)}
                      />
                      عمودي (Vertical)
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="screen"
                        value="H"
                        checked={screen === "H"}
                        onChange={(e) => setscreen(e.target.value)}
                      />
                      أفقي (Horizontal)
                    </label>
                  </div>
                </div>
                <div className="field">
                  <label><TbWorldLatitude    />خط العرض</label>
                  <input type="text" value={lat} onChange={(e) => setLat(e.target.value)} />
                </div>
                <div className="field">
                  <label><TbWorldLongitude     />خط الطول</label>
                  <input type="text" value={long} onChange={(e) => setLong(e.target.value)} />
                </div>
                <div className='fieldGroup'>
                  <div>
                    <div className="field">
                      <label>فترة الفجر</label>
                      <input type="text" value={fajr } placeholder={fajr||"10 تلقائي "} onChange={(e) => setFajr(e.target.value)} />
                    </div>
                    <div className="field">
                      <label>فترة الشروق</label>
                      <input type="text" value={sunrise } placeholder={sunrise||"10 تلقائي "} onChange={(e) => setSunrise(e.target.value)} />
                    </div>
                    
                  </div>
                  <div>
                    <div className="field">
                      <label>فترة الظهر</label>
                      <input type="text" value={dhuhr } placeholder={dhuhr||"15 تلقائي "} onChange={(e) => setDhuhr(e.target.value)} />
                    </div>
                    <div className="field">
                      <label>فترة العصر</label>
                      <input type="text" value={asr } placeholder={asr||"10 تلقائي "} onChange={(e) => setAsr(e.target.value)} />
                    </div>
                  </div>
                  <div>
                    
                    <div className="field">
                      <label>فترة المغرب</label>
                      <input type="text" value={maghrib } placeholder={maghrib||"5 تلقائي "} onChange={(e) => setMaghrib(e.target.value)} />
                    </div>
                    <div className="field">
                      <label>فترة العشاء</label>
                      <input type="text" value={isha } placeholder={isha||"15 تلقائي "} onChange={(e) => setIsha(e.target.value)} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="leftSideForm">
                <div className="field">
                  <label><MdOutlineImage />صورة الخلفية</label>
                  {imagePreview ? (
                    <>
                      <img src={imagePreview} alt="Current Background" />
                      <button type="button" className='chnageImageBtn' onClick={handleImageChange}>تغيير الصورة</button>
                      <input
                        type="file"
                        ref={imageInputRef}
                        style={{ display: 'none' }}
                        onChange={(e) => handleImageUpload(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <input
                      type="file"
                      ref={imageInputRef}
                      onChange={(e) => handleImageUpload(e.target.files[0])}
                    />
                  )}
                </div>
                <div className="field">
                  <label><MdOutlineVolumeUp />الأذان</label>
                  {adhanPreview ? (
                    <>
                      <audio src={adhanPreview} controls />
                      <button type="button" className='changeAdhenBtn' onClick={handleAdhanChange}>تغيير الأذان</button>
                      <input
                        type="file"
                        ref={adhanInputRef}
                        style={{ display: 'none' }}
                        accept="audio/*"
                        onChange={(e) => handleAdhanUpload(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <input
                      type="file"
                      ref={adhanInputRef}
                      accept="audio/*"
                      onChange={(e) => handleAdhanUpload(e.target.files[0])}
                    />
                  )}
                </div>
              </div>
            </div>

            <input type="submit" value="تأكيد" className="sendBtn" />
          </form>
        </div>
        <div className='infoHolder'>
          <div className='todayDate'>
            {hijriDate}
          </div>
          <div className='booksHolder'>
            <h1> <TbBooks />كتب اسلامية للقراءة </h1>
            <div className='books'>
              {books?.map((book , index) => book?.description?.length > 1 && book?.description?.length < 400 &&(
                <article className='book' key={index}>
                  <h1>{book?.title}</h1>
                  <p>{book?.description}</p>
                  <a href={book?.attachments[0]?.url} download target="_blank" rel="noopener noreferrer"><MdOutlineFileDownload />تحميل الكتاب</a>
                </article>
              ))}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default MosqueSettings;
