import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage"; // Add this to initialize Firebase Storage

const firebaseConfig = {
    apiKey: "AIzaSyAoO1sTD7c29cXuf7Ym2EFWFWf4sT-dhGQ",
    authDomain: "manarahclock.firebaseapp.com",
    projectId: "manarahclock",
    storageBucket: "manarahclock.appspot.com",
    messagingSenderId: "787657770789",
    appId: "1:787657770789:web:028b6a3eb9cc7a32827ccc"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Realtime Database
const db = getDatabase(app);

// Initialize Firebase Storage
const storage = getStorage(app); // Initialize Storage here

export { app, db, storage }; // Export storage as well
