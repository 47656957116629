import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./style/main.css"
import withClearCache from "./components/ClearCache";

const root = ReactDOM.createRoot(document.getElementById('root'));
const ClearCacheComponent = withClearCache(App);
root.render(
  <React.StrictMode>
    <ClearCacheComponent />
  </React.StrictMode>
);

