import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from "./pages/Home"
import ClientLogin from './pages/ClientLogin';
import ClientDash from "./pages/ClientDash";
import Clock from './pages/Clock';
import AdminLogin from './pages/AdminLogin';
import AdminDash from './pages/AdminDash';

function App() {
  return (
    <div>
      <Router>
        <Routes>
          {/* Home Page  */}
          <Route path='*' index element={<Home/>}></Route>

          {/* Admin Login + Dash */}
          <Route path="/admin/login" element={<AdminLogin/>} />
          <Route path="/admin" element={<AdminDash/>} />

          {/* Client Login + Dash */}
          <Route path="/client/login" element={<ClientLogin/>} />
          <Route path="/client/:emailUserName" element={<ClientDash/>} />

          {/* Masjid Clock */}
          <Route path="/masjid/:masjidName" element={<Clock/>} />
        </Routes>
      </Router>
  </div>
  )
}

export default App
